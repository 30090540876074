<template>
  <div id="ContatoConsulta" :style="{ 'background-color':COR_PRINCIPAL }">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <!-- Título da página -->
        <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
          >Contatos
        </v-toolbar-title>
        <!-- Fim Título da página -->

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title
          class="mt-0 mb-10 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
            <v-text-field
              id="txtBuscar"
              ref="txtBuscar"
              v-on:keyup="filtro = $event.target.value"
              prepend-inner-icon="mdi-magnify"
              dense
              autofocus
              v-model="search"
              cache-items
              class="search-input"
              v-bind:class="{'search-input-longo': search_input_focus}"
              flat
              dark
              label="Buscar..."
              solo-inverted
              clearable
              @focus="onFocus($event)"
              @blur="onBlur($event)"
              tabindex="-1"
            >
            </v-text-field>

            <router-link
              :to="{ name: 'ContatoCadastro' }"
              class="nav-link"
              aria-current="page"
              tabindex="-1"
            >
              <v-btn class="text-white" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span>Novo</span>
              </v-btn>
            </router-link>

<!-- ---------------------------- -->
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  class="text-white mr-2"
                    :color="COR_PRINCIPAL"
                    elevation="0"
                    :small="isMobile"
                    v-bind="attrs"
                    v-on="on"
                    >
                  <v-icon class="mr-1" color="light-blue accent-2"
                    >mdi-view-headline</v-icon
                  >

                  <span>Opções</span>
                </v-btn>
              </template>

              <!-- Importar dos contatos do Celular -->
              <!-- <v-list class="py-0">
                <v-list-item  class="px-2"
                              @click="importaContatos"
                              v-if="isMobileDevice">
                  <v-list-item-title>
                    <v-icon class="mr-1" color="green accent-2">mdi-account-arrow-left-outline</v-icon>
                      <span >Importar dos Contatos</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list> -->
            </v-menu>

<!-- ---------------------------------- -->

          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>
    
    <!-- Help -->
    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Fim Help -->

    <v-row class="mt-n11 w-100 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100" >
        <v-container class="container-principal container-rounded px-0 w-100 mx-0">

            <!-- Conteiner  -->
            <v-data-table
              id="virtualScrollTable"
              ref="virtualScrollTable"
              :items="store_Contato.dados"
              :headers="headers"
              :loading="loading"
              fixed-header
              dense
              :height="tableHeight"
              :items-per-page="!isMobile ? 20 : -1"
              hide-default-footer
              :hide-default-header="isMobile"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
              class="data-table"
            >
              <template #item="{ item }">
                <tr v-if="!isMobile">
                  <td class="td-edit">
                    <router-link
                      :to="{
                        name: 'ContatoCadastro',
                        params: { cod_contato: item.cod_contato },
                      }"
                      class="nav-link"
                      aria-current="page"
                      tabindex="-1"
                    >
                      <v-btn icon dark class="btn-icon mr-5" color="grey">
                        <v-icon class="icon-action"> mdi-pencil </v-icon>
                      </v-btn>
                    </router-link>
                  </td>
                  <td class="td-avatar py-1 pl-10">
                    <v-avatar size="50">
                      <!-- <v-img v-if="item.contactId" :src="`content://com.android.contacts/contacts/${item.contactId}/photo`"> </v-img> -->
                      <v-img :src="item.contato_foto"> </v-img>
                    </v-avatar>
                  </td>

                  <td >
                    {{ item.contato_nome }}
                  </td>

                  <td >
                    {{ item.contato_email }}
                  </td>

                  <td >
                    {{ item.contato_celular }}
                  </td>
                </tr> 

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile">
                  <td>
                    <div style="width:25px !important" class="ml-n4">
                      <router-link
                        :to="{
                          name: 'ContatoCadastro',
                          params: { cod_contato: item.cod_contato },
                        }"
                        class="nav-link"
                        aria-current="page"
                      >
                        <v-btn icon dark class="btn-icon" color="grey">
                          <v-icon class="icon-action"> mdi-pencil </v-icon>
                        </v-btn>
                      </router-link>
                    </div>
                  </td>
                  <td >
                    <div  class="ml-n2">
                      <v-avatar size="45" class="ml-n1">
                        <v-img :src="item.contato_foto"> </v-img>
                      </v-avatar>
                    </div>
                  </td>
                  <td style="width: 100%;">
                    <div
                      style="font-size: 15px; display:block"
                      class="font-weight-bold mt-1">
                      {{ item.contato_nome }}
                    </div>
                    <div
                      style="font-size: 12px; color:#808080 !important; "
                      class="mt-0">
                      {{ item.contato_email }}
                    </div>
                    <div
                      style="font-size: 12px; color:#808080 !important; "
                      class="mt-0 mb-2">
                      {{ item.contato_celular }}
                    </div>
                  </td>

                </tr>
              </template>
            </v-data-table>

    <div style="display:none !important; background-color: #FF0000;" class="d-flex table-footer-prepend pl-2 align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            outlined
            color="primary"
            class="mr-3"
            v-on="on"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Reload Desserts</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            outlined
            color="primary"
            class="mr-3"
            v-on="on"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <span>Add Dessert</span>
      </v-tooltip>
    </div>


        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <!-- Paginacao ------------------------------ -->
    <!-- <v-pagination
      class="mt-3"
      v-model="currentPage"
      :length="store_Contato.paginas"
      @input="handlePageChange"
    ></v-pagination> -->

    <!-- <Footer /> -->
  </div>
</template>

<script>
import { isMobileDevice } from "../../services/global"
import store_usuario from "../../store/store_usuario";
import store_Contato from "./store_Contato";
import { COR_PRINCIPAL } from "../../services/constantes";

export default {
  name: "ContatoConsulta",
  components: {
  },

  data() {
    return {
      store_usuario   : store_usuario,
      store_Contato   : store_Contato,
      isMobileDevice  : null,

      loading: true,
      search: null,
      search_input_focus : false,

      filtro: null,
      currentPage: 1,

      COR_PRINCIPAL : COR_PRINCIPAL,

      contacts : null,
      
      /* Título container-rounded */
      headers: [
        {
          text: "",
        },
        {
          text: "",
          sortable: false,          
        },
        {
          text: "Nome",
          value: "nome",
          sortable: true,
        },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
      ],

    };
  },

  async created() {
    //console.log("created contato consulta ", this.COR_PRINCIPAL);
    this.busca();
    this.isMobileDevice = await isMobileDevice();
  },

  mounted() {
  },

  watch: {
    search(val) {
      this.currentPage = 1;
      this.busca_timeout(val);
    },
  },
 
  computed: {
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 - 60 + 48;
      else
        return window.innerHeight - 149 - 60;
    }

  },

  // mounted() {
    // onResize();
    // window.addEventListener("resize", this.onResize);
  // },

  // destroyed() {
    // window.removeEventListener("resize", this.onResize);
  // },
  
  methods: {

    importaContatos() {

      //console.log(this.store_Contato.dados);
      this.loading = true;

      // Era atraves do pacote: byrds/capacitor-contacts que foi desinstalado
      const  { CapContacts } = Plugins;
      CapContacts.getPermissions();
      CapContacts.getContacts()
        .then(result => {
            // Importando Contatos (somente os que não existem, ou são diferentes)
            this.store_Contato.importaContatos(result)
              .then(resultado => {
                this.loading = false;
                this.busca();
              })
              .catch(erro => {
                this.contacts = 'erro:' + err;
                this.loading = false;
              })

            // let ls_contato = null;
            // for (const contact of result.contacts) {
            //   ls_contato = {  cod_contato     : contact.contactId + 1000,
            //                   contactId       : contact.contactId,
            //                   contato_celular : contact.phoneNumbers[0],
            //                   contato_email   : contact.emails[0],
            //                   contato_nome    : contact.displayName
            //                 }
            //   this.store_Contato.dados.push(ls_contato);
            // }
          this.loading = false;
        })
        .catch(err => {
          this.contacts = [err];
          this.loading = false;
        })
    },

    onBlur() {
        this.search_input_focus = false;
    },

    onFocus() {
        this.search_input_focus = true;
    },

    // handlePageChange(value) {
    //   this.currentPage = value;
    //   this.busca();
    // },

    async busca() {
      this.loading = true;
      var lo_params = { page: this.currentPage };
      if (this.filtro) {
        lo_params.filtro = this.filtro;
      }
      await this.store_Contato.ContatoGet(lo_params);
      this.loading = false;

    },

    busca_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.busca(val);
      }, 450);
    },

    // EXEMPLO
        // onResize() {
        //   if (this.isMobile)
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight + 30 - 90;
        //   else
        //     this.tableHeight = window.innerHeight - this.$refs.toolbar.clientHeight - 90;

        //   if (window.innerWidth < 769)
        //     this.isMobile = true;
        //   else
        //     this.isMobile = false;
        // },
        // toggleAll() {
        //   if (this.selected.length) this.selected = []
        //   else this.selected = this.desserts.slice()
        // },
        // changeSort(column) {
        //   console.log(column);
        //   if (this.pagination.sortBy === column) {
        //     this.pagination.descending = !this.pagination.descending
        //   } else {
        //     this.pagination.sortBy = column
        //     this.pagination.descending = false
        //   }
        // }

  },
};
</script>

<style scoped>

/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.main-title {
  background: var(--accent-color); /* var("--cor-principal"); */
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
  border-radius: 25px 25px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.panel-header {
  height: 60px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.v-btn {
  display: inline-block;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/

.v-data-table-header-mobile {
  background-color: #FFFFFF;
  display: none !important;
}

.wrapper-title,
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
}
.search-input-longo {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
  /* min-width: calc(100% - 15px); */
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */

  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

.container-rounded {
  background: #FFFFFF !important;
  border-radius: 12px !important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.container-fundo {
  height: calc(100vh - 6vh) !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
  /* height: calc(100vh) !important; */
}

.wrapper-title div {
  padding-left: 0px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.row-table-user-tablet {
  display: none;
}

#virtual-scroll-table-tablet {
  display: none;
}


@media (max-width: 1024px) {
  .td-avatar {
    padding-left: 16px !important;
  }

  .td-edit,
  .nav-link {
    padding-right: 0px !important;
  }

  .status_ativo,
  .status_pendente {
    margin-right: 4px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {

  .container,
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    border-radius: 12px !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .title-page {
    margin-left: 16px !important;
  }

  .wrapper-title div {
    padding-left: 0px !important;
    margin-left: -8px;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .avatar {
    margin: 0px 0px 0px 0px !important;
  }
  
  .td-edit-mobile {
    padding-right: 12px !important;
    padding-left: 0px !important;
  }

  .status-mobile {
    display: block;
  }

  .td-status {
    display: none;
  }
 
  .btn-icon {
    margin-right: 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  .container-principal {
    max-width: 100% !important;
  }
  
}

.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}

</style>